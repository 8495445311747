import React, {useRef} from 'react';
import saasquality1 from '../../assets/images/portfolio/saasquality1.png';
import saasquality2 from '../../assets/images/portfolio/saasquality2.png';
import saasquality3 from '../../assets/images/portfolio/saasquality3.png';
import saasquality4 from '../../assets/images/portfolio/saasquality4.png';

import '../../assets/styles/pages/services/SaasQuality.scss';
import { motion, useInView } from 'framer-motion';

const stats = [
  { img: saasquality1, description: '99.9% uptime guarantee' },
  { img: saasquality2, description: '24/7 technical support' },
  { img: saasquality3, description: 'Regular security patches and updates' },
  { img: saasquality4, description: 'Flexible billing options' },
];

const SaasQuality = () => {
const ref = useRef(null);
  const isInView = useInView(ref, { once: false, amount: 0.9 }); // `amount: 0.3` triggers when 30% of the element is visible

  return (
    <div className="saas" ref={ref}>
      <h2>All our SaaS offerings come with:</h2>
      <div className='saas_grid_outer'>
      <div className="saas__grid">

        {stats.map((stat, index) => (
          <motion.div
            key={index}
            className="saas_item"
            initial={{ opacity: 0, y: 50 }}
            animate={isInView ?{ opacity: 1, y: 0 }: {}}
            transition={{ delay: 0.2 * index }}
          >
            <img src={stat.img}/>
            <p>{stat.description}</p>
          </motion.div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default SaasQuality;
