import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomeLayout from "./components/layouts/HomeLayout";
import Home from "./pages/Home";
import About from "./pages/About";
import ProductInfo from "./pages/ProductInfo";
import ContactUs from "./pages/ContactUs";
import NotFound from "./pages/NotFound";
import Services from "./pages/Services";
import AccountDeletion from "./pages/AccountDelete";
import UpdatePassword from "./pages/UpdatePassword";
import UpdateUsername from "./pages/UpdateUsername";
import ResetPassword from "./pages/ResetPassword";
import ResetUsername from "./pages/ResetUsername";
import InvalidCode from "./components/common/InvalidCode";
import CorporateResetPassword from "./pages/CorporateResetPassword";
import CorporateUserDetails from "./pages/CorporateUserDetails";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<HomeLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="delete-account/:code/" element={<AccountDeletion />} />
            <Route path="/product-info" element={<ProductInfo />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/portfolio" element={<Services />} />
            <Route
              path="/update-password/:token/"
              element={<UpdatePassword />}
            />
            <Route path="/reset-password/:token/" element={<ResetPassword />} />
            <Route
              path="/update-username/:token/"
              element={<UpdateUsername />}
            />
            <Route path="/reset-username/:token/" element={<ResetUsername />} />
            <Route
              path="/corporate-create-password/:token/"
              element={<CorporateResetPassword />}
            />
            <Route
              path="/corporate-user-details"
              element={<CorporateUserDetails />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/invalid-code" element={<InvalidCode />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
