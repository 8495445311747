import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { checkLinkCorporate, createPasswordCorporate } from "../utils/apis";
import AntroLogo from "../assets/images/antrologo.png";
import PadlockIcon from "../assets/images/Padlock.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast, ToastContainer } from 'react-toastify';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../assets/styles/pages/UpdatePassword.scss';

const CorporateResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({ password: [], confirmPassword: [] });
  const [touched, setTouched] = useState({ password: false, confirmPassword: false });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  // Validate password
  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) errors.push("Password must be at least 8 characters long.");
    if (password.length > 15) errors.push("Password must not be more than 15 characters long.");
    if (!/[A-Z]/.test(password)) errors.push("Password must contain at least one uppercase letter.");
    if (!/[a-z]/.test(password)) errors.push("Password must contain at least one lowercase letter.");
    if (!/[0-9]/.test(password)) errors.push("Password must contain at least one number.");
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) errors.push('Password must contain at least one special character.');
    return errors;
  };

  const checkFormValidity = () => {
    const passwordErrors = validatePassword(password);
    const confirmPasswordErrors = password !== confirmPassword ? ["Passwords do not match."] : [];
    
    setErrors({
      password: passwordErrors,
      confirmPassword: confirmPasswordErrors,
    });

    const valid = passwordErrors.length === 0 && confirmPasswordErrors.length === 0;
    setIsFormValid(valid);
  };

  // Trigger validation when password or confirmPassword changes
  useEffect(() => {
    checkFormValidity();
  }, [password, confirmPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) return;

    setIsSubmitting(true);
    try {
      const result = await createPasswordCorporate(token, password);
      if (result.success) {
        toast.success("Password reset successfully!");
        setTimeout(() => navigate("/corporate-user-details"), 2000);
      } else {
        toast.error(result.message || "This token is invalid or expired. Please start the process again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  useEffect(() => {
    const validateLink = async () => {
      try {
        const result = await checkLinkCorporate(token);
        if (result.success === false || (result.errors && result.errors.link_verified === false)) {
          navigate("/invalid-code");
        } else if (result.data && result.data.email) {
          setEmail(result.data.email);
        }
      } catch (error) {
        console.error("Error while validating link:", error);
      }
    };
    validateLink();
  }, [token, navigate]);

  return (
    <div className="main-update-password">
      <ToastContainer />
      <div className="header">
        <img src={AntroLogo} alt="Antro Logo" className="logo" />
      </div>
      <img src={PadlockIcon} alt="Padlock Icon" className="lock-icon" />
      <div className="heading">Welcome to Antro</div>
      <div className="container">
        <h2>Create New Password</h2>
        <form onSubmit={handleSubmit} className="form-corporate-newpassword">
          <div className="input-wrapper">
            <input
              type="text"
              id="email"
              placeholder="Email"
              value={email}
              readOnly
              className="email-input"
            />
          </div>

          <div className={`input-wrapper${(touched.password && errors.password.length > 0) ? ' error' : ''}`}>
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              placeholder="New Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setTouched({ ...touched, password: true });
              }}
              required
              autoComplete="new-password"
              className="password-input"
            />
            <span className="toggle-password" onClick={togglePasswordVisibility}>
              <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} className="eye-icon" />
            </span>
          </div>
          {touched.password && errors.password.map((error, index) => (
            <div key={index} className="error-message">{error}</div>
          ))}

          <div className={`input-wrapper${(touched.confirmPassword && errors.confirmPassword.length > 0) ? ' error' : ''}`}>
            <input
              type={showConfirmPassword ? "text" : "password"}
              id="confirmPassword"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setTouched({ ...touched, confirmPassword: true });
              }}
              required
              autoComplete="new-password"
              className="password-input"
            />
            <span className="toggle-password" onClick={toggleConfirmPasswordVisibility}>
              <FontAwesomeIcon icon={showConfirmPassword ? faEye : faEyeSlash} className="eye-icon" />
            </span>
          </div>
          {touched.confirmPassword && errors.confirmPassword.map((error, index) => (
            <div key={index} className="error-message">{error}</div>
          ))}

          <button type="submit" id="submitButton" disabled={isSubmitting || !isFormValid}>
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CorporateResetPassword;
