import React, {useRef} from 'react';
import Achievements1 from '../../assets/images/portfolio/achievements1.png';
import '../../assets/styles/pages/services/Achievements.scss';
import { motion, useInView } from 'framer-motion';

const stats = [
  { value: '5M+', description: 'Digital Identities Verified' },
  { value: '99.9%', description: 'Uptime for our Cloud' },
  { value: '50+', description: 'Countries Served' },
  { value: '24/7', description: 'Customer Support' },
];

const Achievements = () => {
const ref = useRef(null);
  const isInView = useInView(ref, { once: false, amount: 0.9 }); // `amount: 0.3` triggers when 30% of the element is visible

  return (
    <div className="achievements" ref={ref}>
    <div className='milestone-sub-heading'>Milestones of success</div>
      <h1>Our Achievements</h1>
      <div className='milestone-sub-heading-2'>Remarkable milestones that showcase our commitment to secure identities worldwide.</div>
      <div className="achievements__grid">
        <div className='bg-cover' style={{backgroundImage: `url(${Achievements1})`}}></div>
        {stats.map((stat, index) => (
          <motion.div
            key={index}
            className="achievement"
            initial={{ opacity: 0, y: 50 }}
            animate={isInView ?{ opacity: 1, y: 0 }: {}}
            transition={{ delay: 0.2 * index }}
          >
            <h3>{stat.value}</h3>
            <p>{stat.description}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Achievements;
