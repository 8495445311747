import React, { useState, useEffect } from "react";
import ContactUsBg from "../assets/images/contact-us-bg.png";
import PhoneContactus from "../assets/images/contactUs/ic_round-phone.png";
import EmailContactus from "../assets/images/contactUs/ic_round-mail.png";
import AddressContactus from "../assets/images/contactUs/fluent_location-16-filled.png";
import TwitterContactus from "../assets/images/contactUs/mdi_twitter.png";
import FacebookContactus from "../assets/images/contactUs/ri_facebook-fill.png";
import InstagramContactus from "../assets/images/contactUs/mdi_instagram.png";
import LinkedinContactus from "../assets/images/contactUs/ri_linkedin-fill.png";
import MessageIcon from "../assets/images/contactUs/Sent Email.png";
import "../assets/styles/pages/ContactUs.scss";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    agree: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    // You can handle form submission here (e.g., API call)
  };

  return (
    <div className="contact-container">
      <div className="background-image-outer">
        <img className="background-image" src={ContactUsBg} />
        <div className="background-text">
          <div className="background-text-inner">
            <div className="heading">Get In Touch</div>
            <div className="sub-heading">
              Let's Connect and Start the Conversation
            </div>
          </div>
        </div>
      </div>
      <div className="contact-container-inner">
        <div className="contact-info-main">
          <div className="contact-info">
            <div className="contact-info-heading">Contact Information</div>
            <div className="contact-info-sub-heading">
              Reach Us Anytime, We’re Here to Help
            </div>
            <div className="contact-info-phone">
              <span className="phone-img">
                <img src={PhoneContactus} />
              </span>
              <div className="phone-number">
                <span>+91 70970 02759</span>
                {/* <span>+1 (123) 456-7890</span> */}
              </div>
            </div>
            <div className="contact-info-email">
              <span className="email-img">
                <img src={EmailContactus} />
              </span>
              <span className="text-email">itadmin@antrocorp.com</span>
            </div>
            <div className="contact-info-address">
              <span className="location-img">
                <img src={AddressContactus} />
              </span>
              <span className="text-address">
                H.No. 247/3RT, Ramakrishna Nivas, Sanjeeva Reddy Nagar,
                Hyderabad-500038
              </span>
            </div>
            <div className="social-media">
              {/* <a href="https://x.com/i/flow/login" aria-label="Twitter">
                <img src={TwitterContactus} />
              </a>
              <a href="https://www.facebook.com/login/" aria-label="Facebook">
                <img src={FacebookContactus} />
              </a>
              <a href="https://www.instagram.com/" aria-label="Instagram">
                <img src={InstagramContactus} />
              </a> */}
              <a href="https://www.linkedin.com/company/antro-pai-technologies-pvt-ltd/?originalSubdomain=in" aria-label="Linkedin">
                <img src={LinkedinContactus} />
              </a>
            </div>
          </div>
        </div>
        <div className="contact-form">
          <div className="contact-form-heading-icon">
            <div className="form-heading">Send us a Message</div>
            <img className="message-icon" src={MessageIcon} />
          </div>
          <form onSubmit={handleSubmit}>
            <div className="main-form-group">
              <div className="form-group">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="First name"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Last name"
                  required
                />
              </div>
            </div>

            <div className="main-form-group">
              <div className="form-group">
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email ID"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="phone">Phone Number</label>
                <div className="phone-input-wrapper">
                  <select
                    name="countryCode"
                    id="countryCode"
                    value={formData.countryCode}
                    onChange={handleChange}
                    required
                  >
                    <option value="+91">IND</option>
                    <option value="+1">USA</option>
                    <option value="+44">UK</option>
                    <option value="+61">AUS</option>
                    <option value="+81">JAP</option>
                  </select>
                  <div className="line-bet"></div>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Phone number"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="main-form-group">
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Tell us what we can help with..."
                required
              />
            </div>
            </div>
            <div className="main-form-group">
            <div className="form-group checkbox-group">
              <input
                type="checkbox"
                id="agree"
                name="agree"
                checked={formData.agree}
                onChange={handleChange}
                required
              />
              <label htmlFor="agree">
                I'd like to receive more information about the company. I
                understand and agree to the <a href="#">Privacy Policy</a>.
              </label>
            </div>
            </div>

            <button type="submit" className="submit-btn">
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
