import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import AntroCardMobile from '../../assets/images/homepage/slide1.png';
import AntroCardMobile2 from '../../assets/images/homepage/slide2.png';
import AntroCardMobile3 from '../../assets/images/homepage/slide3.png';
import AntroCardMobile4 from '../../assets/images/homepage/slide4.png';
import AntroCardMobile5 from '../../assets/images/homepage/slide5.png';
import ChieldDuotone from '../../assets/images/homepage/chield_duotone.png';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "../../assets/styles/components/carousel1.scss"

const ImageCarousel = () => {
  return (
    <div className="carousel-wrapper">
      <Carousel showThumbs={false} showStatus={false} autoPlay={false} infiniteLoop showArrows={false}>
        <div className='carousel-tab tab1'>
          <div className='tab1-top'>
            <div className='tab1-top-inner'>
                <div className='line1'><span class="chield-duotone-outer"><img class="chield-duotone-inner" src={ChieldDuotone}/></span>Your Security, Our priority</div>
                <div className='line2'>
                    <div className='line2-a'>Protecting <span> Identities,</span></div>
                    <div className='line2-b'>Preserving <span>Trust</span></div>
                </div>
                <div className='line3'>Patented Pre-Authenticated Identity (PAI) Model</div>
            </div>
          </div>
          <div className='tab1-bottom'>
                <img src={AntroCardMobile} />
          </div>
        </div>
        <div className='carousel-tab tab2'>
          <div className='tab2-top'>
            <div className='tab2-top-inner'>
                <div className='line1'><span class="chield-duotone-outer"><img class="chield-duotone-inner" src={ChieldDuotone}/></span>Effortless Security, Seamless Experience</div>
                <div className='line2'>
                    <div className='line2-a'>Secure Your <span>Identity,</span></div>
                    <div className='line2-b'>Simplify Your <span>Access</span></div>
                </div>
                <div className='line3'>Real-Time Identity Verification</div>
            </div>
          </div>
          <div className='tab2-bottom'>
                <img src={AntroCardMobile2} />
          </div>
        </div>
        <div className='carousel-tab tab3'>
          <div className='tab3-top'>
            <div className='tab3-top-inner'>
                <div className='line1'><span class="chield-duotone-outer"><img class="chield-duotone-inner" src={ChieldDuotone}/></span>Building Confidence, One Transaction at a Time</div>
                <div className='line2'>
                    <div className='line2-a'>Empowering <span> Trust</span> In</div>
                    <div className='line2-b'>Every <span>Transaction</span></div>
                </div>
                <div className='line3'>Dynamic QR Code Generation</div>
            </div>
          </div>
          <div className='tab3-bottom'>
                <img src={AntroCardMobile3} />
          </div>
        </div>
        <div className='carousel-tab tab4'>
          <div className='tab4-top'>
            <div className='tab4-top-inner'>
                <div className='line1'><span class="chield-duotone-outer"><img class="chield-duotone-inner" src={ChieldDuotone}/></span>Securing What Matters Most</div>
                <div className='line2'>
                    <div className='line2-a'>Your <span> Identities,</span> Our</div>
                    <div className='line2-b'><span>Priority</span></div>
                </div>
                <div className='line3'>User-Friendly Mobile App</div>
            </div>
          </div>
          <div className='tab4-bottom'>
                <img src={AntroCardMobile4} />
          </div>
        </div>
        <div className='carousel-tab tab5'>
          <div className='tab5-top'>
            <div className='tab5-top-inner'>
                <div className='line1'><span class="chield-duotone-outer"><img class="chield-duotone-inner" src={ChieldDuotone}/></span>Securing What Matters Most</div>
                <div className='line2'>
                    <div className='line2-a'>Global Recognition</div>
                    <div className='line2-b'><span>For Every Individual</span></div>
                </div>
                <div className='line3'>Your unique identity, safeguarded worldwide</div>
            </div>
          </div>
          <div className='tab5-bottom'>
                <img src={AntroCardMobile5} />
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default ImageCarousel;
