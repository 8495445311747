import React, { useRef } from 'react';
import { MdCall } from "react-icons/md";
import { IoMdMail } from "react-icons/io";
import { MdLocationPin } from "react-icons/md";
import { FaLinkedin } from "react-icons/fa";
import { motion, useInView } from 'framer-motion';
import '../../assets/styles/pages/services/Contact.scss';

const ContactUs = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false, amount: 0.3 });

  return (
    <div className="contact-us" ref={ref}>
      <motion.h2 
        initial={{ opacity: 0, y: -50 }} 
        animate={isInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8 }}
        className="contact-us__heading"
      >
        Contact Us
      </motion.h2>
      <motion.p 
        initial={{ opacity: 0, y: -50 }} 
        animate={isInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8, delay: 0.2 }}
        className="contact-us__subheading"
      >
        Ready to transform your business with cutting-edge technology?
      </motion.p>

      <div className="contact-us__content">
        {/* Left Side: Contact Information */}
        <motion.div 
          className="contact-us__info"
          initial={{ opacity: 0, x: -50 }} 
          animate={isInView ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 0.8 }}
        >
          <h3>Contact Information</h3>
          <p>Reach Us Anytime, We’re Here to Help</p>
          
          <div className="contact-us__details">
            <div className="contact-us__detail-item">
              <span role="img" aria-label="phone"><MdCall /></span>
              <p>7097002759</p>
            </div>
            <div className="contact-us__detail-item">
              <span role="img" aria-label="email"><IoMdMail /></span>
              <p>itadmin@antrocorp.com</p>
            </div>
            <div className="contact-us__detail-item">
              <span role="img" aria-label="location"><MdLocationPin /></span>
              <div>
              <p>Primary:<br />
              201, SR Nagar, Ramakrishna Niwas Towers, Hyderabad, Telangana 500038, IN</p>
              <p>Secondary:<br />
              Hyderabad Knowledge City, Raidurgam Panmaktha, Plot No 1/C, Sy No 83/1, Hyderabad, Telangana 500081, IN</p>
              </div>
            </div>
          </div>

          <div className="contact-us__social">
            <p>Follow us on:</p>
            <a href="https://www.linkedin.com/company/antro-pai-technologies-pvt-ltd/?originalSubdomain=in"><FaLinkedin /></a>
          </div>
        </motion.div>

        {/* Right Side: Contact Form */}
        <motion.div 
          className="contact-us__form"
          initial={{ opacity: 0, x: 50 }} 
          animate={isInView ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 0.8 }}
        >
          <h3>Let's Start A Conversation!</h3>

          <form>
            <div className="contact-us__form-row">
              <div className="contact-us__form-group">
                <label>First name</label>
                <input type="text" placeholder="Enter your first name" />
              </div>
              <div className="contact-us__form-group">
                <label>Last name</label>
                <input type="text" placeholder="Enter your last name" />
              </div>
            </div>

            <div className="contact-us__form-row">
              <div className="contact-us__form-group">
                <label>Email ID</label>
                <input type="email" placeholder="Enter your email ID" />
              </div>
              <div className="contact-us__form-group">
                <label>Phone number</label>
                <input type="tel" placeholder="+1 (231) 231-2312" />
              </div>
            </div>

            <div className="contact-us__form-group">
              <label>Message</label>
              <textarea placeholder="Tell us what we can help with you..."></textarea>
            </div>

            <div className="contact-us__form-group" style={{ flexDirection: "row" }}>
              <input type="checkbox" />
              <label style={{ marginBottom: "0rem" }}>
                I’d like to receive more information about the company. I understand and agree to the Privacy Policy.
              </label>
            </div>

            <div className="contact-us__form-buttons">
              <button type="button" className="btn-cancel">Cancel</button>
              <button type="submit" className="btn-submit">Submit</button>
            </div>
          </form>
        </motion.div>
      </div>
    </div>
  );
};

export default ContactUs;
