import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fetchAccountDeletionData, deleteAccount } from "../utils/apis";
import BuildingIcon from "../assets/images/delete-account/buildingicon.svg";
import MessageIcon from "../assets/images/delete-account/messageicon.svg";
import CallIcon from "../assets/images/delete-account/callicon.svg";
import DummyImg from "../assets/images/delete-account/DummyImg.svg";
import EmailIcon from "../assets/images/delete-account/profilemsgicon.png";
import PhoneIcon from "../assets/images/delete-account/callicon.svg";
import DocumentPng from "../assets/images/delete-account/document-png.svg";
import DocumentPdf from "../assets/images/delete-account/document-pdf.svg";
import DocumentJpg from "../assets/images/delete-account/document-jpg.svg";
import "../assets/styles/pages/AccountDeletion.scss";

const AccountDeletion = () => {
  const { code } = useParams();
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);
  const [userData, setUserData] = useState(null);
  const [profiles, setProfiles] = useState([]);
  const [documents, setDocuments] = useState([]);

  // Fetch data when component mounts
  useEffect(() => {
    fetchDataForDeletion();
  }, []);

  const fetchDataForDeletion = async () => {
    try {
      const data = await fetchAccountDeletionData(code);
      console.log(data);

      setUserData(data.data.user_account);
      setProfiles(data.data.profiles);
      setDocuments(data.data.user_documents);
    } catch (error) {
      setInvalidCode(true);
      console.error("Error fetching data:", error);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const data = await deleteAccount(code);
      if (data.success) {
        navigate("/"); // Redirect to home on successful deletion
      } else {
        console.error("Account deletion failed:", data.message);
        alert(data.message || "Error deleting your account. Please try again.");
      }
    } catch (error) {
      console.error("There was a problem with the delete request:", error);
    }
  };

  const truncateFileName = (fileName, maxLength) => {
    if (fileName.length > maxLength) {
      return fileName.slice(0, maxLength) + "...";
    }
    return fileName;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", { month: "short", day: "numeric" });
  };
  return (
    <div className="page-wrapper">
      <div className="confirming-box">
        <div className="confirming-box-message">
          <div className="heading-main">Confirm Account Deletion</div>
          <div className="sub-heading-main">
            This action is irreversible, and all your data will be permanently
            deleted.
          </div>
        </div>

        <div className="main-modalsection">
          {/* User Account Data Section */}
          <div className="modalsection-heading">
            <div id="dataSection" className="datasection-main">
              {userData && (
                <div className="data-profile-inner">
                  <div className="value-name">
                    <span className="first-initial-box">
                      {userData.first_name.charAt(0).toUpperCase()}
                    </span>
                    <span className="first_name-data">
                      {userData.first_name.charAt(0).toUpperCase() +
                        userData.first_name.slice(1)}
                    </span>
                    <span className="last_name-data">
                      {" "}
                      {userData.last_name.charAt(0).toUpperCase() +
                        userData.last_name.slice(1)}
                    </span>
                  </div>
                </div>
              )}

              <div className="data-profile-inner-data">
                <div className="data-designation">
                  <span className="label-icon-designation">
                    <img
                      src={BuildingIcon}
                      alt="designation"
                      className="building-office-light-icon-img"
                    />
                  </span>
                  <div className="icon-name">
                    <span className="designation-icon">Date of Birth</span>
                    <div className="value-designation_id">
                      {userData?.date_of_birth
                        ? new Date(userData?.date_of_birth).toLocaleDateString()
                        : "Date of Birth Not Available"}
                    </div>
                  </div>
                </div>
                <div className="partition-line-vertical"></div>
                <div className="data-phone_number">
                  <span className="label-icon-phone_number">
                    <img
                      src={CallIcon}
                      alt="phone"
                      className="phone-icon-img"
                    />
                  </span>
                  <div className="icon-name">
                    <span className="phone_number-icon">Mobile Number</span>
                    <span className="value-phone_number">
                      {userData?.phone_number}
                    </span>
                  </div>
                </div>
                <div className="partition-line-vertical"></div>
                <div className="data-email">
                  <span className="label-icon-email">
                    <img
                      src={MessageIcon}
                      alt="email"
                      className="email-icon-img"
                    />
                  </span>
                  <div className="icon-name">
                    <span className="email-icon">Email ID</span>
                    <span className="value-email">{userData?.email}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Profiles Section */}
          <div className="modalsection-heading">
            <h3>Profiles</h3>
            <div id="profilesSection" className="profiles-outer">
              {profiles.length > 0 ? (
                profiles.map((profile) => (
                  <div key={profile.id} className="profile-card">
                    <div className="profile-header">
                      <img
                        className="profile-picture"
                        src={DummyImg}
                        alt="Profile Picture"
                      />
                    </div>
                    <div className="profile-info">
                      <div className="profile-name-designation">
                        <div className="full-name">
                          {" "}
                          <span className="first_name-data">
                            {userData.first_name}
                          </span>
                          <span className="last_name-data">
                            {userData.last_name}
                          </span>
                        </div>
                        <div className="profile-designation">
                          {profile.designation}
                        </div>
                      </div>
                    </div>
                    <div className="profile-contact">
                      <div className="phone-email-main">
                        <div className="contact-item-phone">
                          <div className="contact-icon">
                            {" "}
                            <img src={PhoneIcon} alt="Phone Icon" />
                          </div>
                          <div className="profile-phone_number">
                            {profile.phone_number}
                          </div>
                        </div>
                        <div className="contact--email">
                          <div className="profile-email-icon">
                            <img src={EmailIcon} alt="Email Icon" />
                          </div>
                          <div className="profile-email_id">
                            {profile.email.length > 18
                              ? profile.email.slice(0, 18) + ".."
                              : profile.email}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No profiles available</p>
              )}
            </div>
          </div>

          {/* Documents Section */}
          <div className="modalsection-heading">
            <h3>Documents</h3>
            <div id="documentsSection" className="main-document-section">
              {documents.length > 0 ? (
                documents.map((document) => {
                  const date = new Date(document.created_at);
                  const formattedDate = date.toLocaleDateString();

                  // Get the file extension and assign the corresponding icon
                  const fileExtension = document.file_name
                    .split(".")
                    .pop()
                    .toLowerCase();
                  let documentIcon;

                  switch (fileExtension) {
                    case "pdf":
                      documentIcon = DocumentPdf;
                      break;
                    case "jpg":
                    case "jpeg": // In case there are jpeg files
                      documentIcon = DocumentJpg;
                      break;
                    case "png":
                      documentIcon = DocumentPng;
                      break;
                    default:
                      documentIcon = DummyImg; // Fallback image for unknown formats
                  }

                  return (
                    <div key={document.id} className="documents-outer">
                      <div className="document-profile-inner">
                        <span className="label-category-icon">
                          <img
                            src={documentIcon}
                            alt={`${fileExtension} document`}
                            className="category-icon"
                          />
                        </span>
                        <div className="file-details">
                          <div className="value-file_name">
                            {truncateFileName(document.file_name, 10)}
                          </div>
                          <div className="status-date">
                            <span className="updated-text">Updated</span>{" "}
                            <span className="date">
                              {formatDate(document.updated_dt)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No documents available</p>
              )}
            </div>
          </div>
        </div>

        {/* Button Section */}
        <div className="main-btn-section">
          <button onClick={() => navigate("/")} className="cancel-btn">
            Cancel
          </button>
          <button onClick={() => setModalVisible(true)} className="delete-btn">
            Delete Account
          </button>
        </div>

        {/* Modal for confirmation */}
        {modalVisible && (
          <div className="modal">
            <div className="modal_content">
              <span className="close" onClick={() => setModalVisible(false)}>
                &times;
              </span>
              <p>Are you sure you want to delete this account?</p>
              <button
                onClick={handleDeleteAccount}
                className="modal-delete-btn"
              >
                Confirm Deletion
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountDeletion;
