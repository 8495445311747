// src/pages/updateUserDetails.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchResetLinkData, updateUserDetails } from '../utils/apis';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import AntroLogo from "../assets/images/antrologo.png";
import PadlockIcon from "../assets/images/Padlock.svg";
import '../assets/styles/pages/UpdatePassword.scss';

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate(); // Initialize useNavigate
  const [secretCode, setSecretCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [touched, setTouched] = useState({});

  useEffect(() => {
    if (!token) {
      window.location.href = '/invalid-code';
    } else {
      setSecretCode(token);
      checkLink(token);
    }
  }, [token]);

  const checkLink = async (token) => {
    try {
      const data_type = "reset_password";
      const result = await fetchResetLinkData(data_type, token);
      if (!result.success && result.errors.link_verified === false) {
        window.location.href = '/invalid-code';
      }
    } catch (error) {
      console.log('Error checking link:', error);
      window.location.href = '/invalid-code';
    }
  };

  const togglePasswordVisibility = (field) => {
    if (field === 'newPassword') {
      setShowNewPassword(!showNewPassword);
    } else if (field === 'confirmPassword') {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const validatePassword = (password) => {
    const errors = [];
    if (password.length === 0) return errors;
    if (password.length < 8) errors.push('Password must be at least 8 characters long.');
    if (password.length > 15) errors.push('Password must not be more than 15 characters long.');
    if (!/[A-Z]/.test(password)) errors.push('Password must contain at least one uppercase letter.');
    if (!/[a-z]/.test(password)) errors.push('Password must contain at least one lowercase letter.');
    if (!/[0-9]/.test(password)) errors.push('Password must contain at least one number.');
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) errors.push('Password must contain at least one special character.');
    return errors;
  };

  const checkFormValidity = () => {
    const passwordErrors = validatePassword(newPassword);
    const confirmPasswordErrors = [];
    
    if (newPassword !== confirmPassword) {
      confirmPasswordErrors.push('Passwords do not match.');
    }

    setErrors({
      newPassword: passwordErrors,
      confirmPassword: confirmPasswordErrors,
    });

    setIsSubmitEnabled(
      passwordErrors.length === 0 &&
      confirmPasswordErrors.length === 0
    );
  };

  useEffect(() => {
    checkFormValidity();
  }, [newPassword, confirmPassword]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const passwordErrors = validatePassword(newPassword);
    const confirmPasswordErrors = [];

    if (newPassword !== confirmPassword) {
      confirmPasswordErrors.push('Passwords do not match.');
    }

    setErrors({
      newPassword: passwordErrors,
      confirmPassword: confirmPasswordErrors,
    });

    if (passwordErrors.length > 0 || confirmPasswordErrors.length > 0) {
      return; // Return early if there are errors
    }

    try {
      const data_type = "reset_password";
      const result = await updateUserDetails(data_type, secretCode, newPassword);

      if (result.success) {
        toast.success('Your password has been reset successfully.');
        
        // Show redirection message and navigate
        document.querySelector('.container').innerHTML = `
          <h2>Password Reset</h2>
          <p>Your password has been reset successfully. Redirecting to home page in 5 seconds...</p>
        `;

        // Redirect to home page after 5 seconds
        setTimeout(() => {
          navigate("/"); // Redirect to home page
        }, 5000);
      } else {
        toast.dismiss(); // Dismiss previous toasts
        toast.error(result.message || 'An error occurred. Please try again.');
      }
    } catch (error) {
      toast.error('Your password was not reset successfully. Please try again.');
      console.log('API Error:', error);
    }
  };

  return (
    <div className='update-password-layout'>
      <ToastContainer />
      <div className="main-update-password">
        <div className="header">
          <img src={AntroLogo} alt="Antro Logo" className="logo" />
        </div>
        <img src={PadlockIcon} alt="Padlock Icon" className="lock-icon" />
        <div className="container">
          <h2>Reset Password</h2>
          <form id="newPasswordForm" className="form-corporate-newpassword" onSubmit={handleSubmit}>
            <div className={`input-wrapper${errors.newPassword && errors.newPassword.length > 0 ? ' error' : ''}`}>
              <input
                className="password-input"
                type={showNewPassword ? 'text' : 'password'}
                id="newPassword"
                maxLength="15"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  setTouched({ ...touched, newPassword: true }); // Mark input as touched
                }}
              />
              <span
                className="toggle-password"
                onClick={() => togglePasswordVisibility('newPassword')}>
                <FontAwesomeIcon icon={showNewPassword ? faEye : faEyeSlash} className="eye-icon" />
              </span>
            </div>
            {touched.newPassword && errors.newPassword && errors.newPassword.map((error, index) => (
              <div key={index} className="error-message">{error}</div>
            ))}
            <div className={`input-wrapper${errors.confirmPassword && errors.confirmPassword.length > 0 ? ' error' : ''}`}>
              <input
                className="password-input"
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                maxLength="15"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setTouched({ ...touched, confirmPassword: true }); // Mark input as touched
                }}
              />
              <span
                className="toggle-password"
                onClick={() => togglePasswordVisibility('confirmPassword')}>
                <FontAwesomeIcon icon={showConfirmPassword ? faEye : faEyeSlash} className="eye-icon" />
              </span>
            </div>
            {touched.confirmPassword && errors.confirmPassword && errors.confirmPassword.map((error, index) => (
              <div key={index} className="error-message">{error}</div>
            ))}
            <button type="submit" id="submitButton" disabled={!isSubmitEnabled}>Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
